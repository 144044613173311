import { useState, useCallback } from "react";
import Box from "@mui/material/Box";
import { useAuth0 } from "@auth0/auth0-react";
import SearchFilters from "../ui/SearchFilters";
import SignalGrid from "./SignalGrid";
import TapTradeLegGrid from "../trades/TapTradeLegGrid";
import { fetchSignalList } from "../../store/signalListActions";

const SignalList = (props) => {
  const [signalList, setSignalList] = useState([]);

  const { getAccessTokenSilently } = useAuth0();
  const [trade, setTrade] = useState(null);

  const runSearchHandler = useCallback(
    async (filters) => {
      const { dateFrom, dateTo } = filters;
      const token = await getAccessTokenSilently();
      fetchSignalList(token, dateFrom, dateTo).then((res => {
        setSignalList(res);
      }));
    },
    [getAccessTokenSilently]
  );

  const showTradeHandler = (trade) => {
    if (trade && trade.legs) {
      setTrade(trade);
    } else {
      setTrade(null);
    }
  };

  return (
    <div>
      <Box>
        <Box sx={{ marginBottom: 1 }}>
          <SearchFilters onFilterChange={runSearchHandler} initialDateFrom={new Date()} initialDateTo={new Date()} />
        </Box>
        <SignalGrid rows={signalList} onShowTradeDetail={showTradeHandler} />
      </Box>
      {trade && trade.legs.length > 0 && (
        <Box marginTop={2}>
          <TapTradeLegGrid trade={trade} showUngroup={false} />
        </Box>
      )}
    </div>
  );
};

export default SignalList;
