import MainNav from "./navigation/MainNav";
import { useAuth0 } from "@auth0/auth0-react";

const Home = () => {
  const { isAuthenticated, isLoading, error } = useAuth0();

  if (isLoading) {
    return <div>Loading..</div>
  }

  if (error) {
    return <div>Oops... {error.message}</div>
  }

  if (isAuthenticated) {
    return(<MainNav/>)
  }

  if (!isAuthenticated) {
    return(<div>Home. Login then do stuff here</div>)
  }
};

export default Home;
