import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { memo} from "react";

const AdmisTradeLegSplitDialog = memo((params) => {
  const { handleClose, handleChange, open } = params;
  
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Split Trade Leg</DialogTitle>
        <DialogContent>
          <DialogContentText>Please enter the amount to split below</DialogContentText>
          <TextField label="Quantity" onChange={(event) => handleChange(event.target.value)}/> 
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {handleClose(true)}}>Cancel</Button>
          <Button onClick={() => {handleClose(false)}}>Split</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});
export default AdmisTradeLegSplitDialog;
