import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { selectTradeById } from "../../store/admisTradeListSlice";
import AdmisTradeLegGrid from "./AdmisTradeLegGrid";
import AdmisTradeGrid from "./AdmisTradeGrid";
import { useState, useCallback } from "react";
import AdmisTradeLegSplitDialog from "./AdmisTradeLegSplitDialog";
import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

const AdmisTradeEditDialog = (props) => {
  const { selectedTradeId, open, handleClose, handleIgnore } = props;

  const tradeInState = useSelector((state) =>
    selectTradeById(state, selectedTradeId)
  );

  // maintain a local version of the trade so the user can elect to either save or cancel
  const [trade, setTrade] = useState({ ...tradeInState });

  const [selectedTradeLeg, setSelectedTradeLeg] = useState([]);
  const [splitLegDialogOpen, setSplitLegDialogOpen] = useState(false);
  const [splitQuantity, setSplitQuantity] = useState(null);
  const [removedLegs, setRemovedLegs] = useState([]);

  const handleUngroupLeg = (removedLeg) => {
    if (trade.legs && trade.legs.length > 1) {
      const newLegs = trade.legs.filter(
        (l) => l.admisTradeLegId !== removedLeg.admisTradeLegId
      );
      let newTrade = { ...trade };
      newTrade.legs = newLegs;
      newTrade.quantity = newTrade.quantity - removedLeg.quantity;
      setTrade(newTrade);
      setRemovedLegs((prevState) => [...prevState, removedLeg]);
    }
  };

  const handleLegSplitShow = useCallback((tradeLeg) => {
    setSelectedTradeLeg(tradeLeg);
    setSplitLegDialogOpen(true);
  }, []);

  const splitLegCloseHandler = useCallback(
    async (cancel) => {
      if (cancel) {
        setSplitQuantity(null);
        setSplitLegDialogOpen(false);
      } else {
        // only update the trade if the user didn't cancel and the quantity is numeric positive but less than abs(total quantity)
        if (
          splitQuantity &&
          splitQuantity > 0 &&
          splitQuantity < Math.abs(selectedTradeLeg.quantity)
        ) {
          // create a new leg with the quantity that has been split off (negative if the leg is sell)
          // this leg needs a unique legId for the grid so we generate a unique negative one
          // the server will know it is new because it is negative
          const maxLegId = Math.max(
            ...trade.legs.map((o) => Math.abs(o.admisTradeLegId))
          );
          const signCorrectQuantity =
            splitQuantity * (selectedTradeLeg.isBuy ? 1 : -1);
          const newLeg = {
            ...selectedTradeLeg,
            quantity: signCorrectQuantity,
            admisTradeLegId: (maxLegId + 1) * -1,
          };

          // the old leg has the quantity minus the split amount
          const oldLegQuantity =
            selectedTradeLeg.quantity - signCorrectQuantity;
          const updatedOldLeg = {
            ...selectedTradeLeg,
            quantity: oldLegQuantity,
          };

          // remove the old leg and add in it's updated version and the new leg
          const updatedTrade = { ...trade };
          let updatedLegs = updatedTrade.legs.map((x) => x);

          let index = updatedLegs.findIndex(
            (i) => i.admisTradeLegId === selectedTradeLeg.admisTradeLegId
          );
          if (index > -1) {
            updatedLegs.splice(index, 1);
            updatedLegs = updatedLegs.concat([newLeg, updatedOldLeg]);
            updatedTrade.legs = updatedLegs;
          }
          setTrade(updatedTrade);
          setSplitQuantity(null);
          setSplitLegDialogOpen(false);
        }
      }
    },
    [splitQuantity, selectedTradeLeg, trade]
  );

  const splitLegQuantityChangeHandler = useCallback((newValue) => {
    setSplitQuantity(newValue);
  }, []);

  if (trade && trade.legs && trade.legs.length > 0) {
    return (
      <div>
        <Dialog open={open} fullWidth={true} maxWidth={"xl"}>
          <DialogTitle>
            <Box>
              <Grid2 container sx={{ flexGrow: 1 }}>
                <Grid2>
                  <Typography variant="h5">
                    Trade #{trade.admisTradeId}
                  </Typography>
                </Grid2>
                <Grid2 mdOffset="auto">
                  {trade.canIgnore && (
                    <IconButton
                      size="small"
                      edge="start"
                      aria-label="ignore trade"
                      title="ignore trade"
                      onClick={() => {
                        handleIgnore(trade);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Grid2>
              </Grid2>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Typography variant="h6">Trade</Typography>
            <AdmisTradeGrid admisTradeRows={[trade]} />

            <Typography variant="h6">
              <br />
              Legs
            </Typography>

            <AdmisTradeLegGrid
              rows={trade.legs}
              onUngroupTrade={handleUngroupLeg}
              onShowLegSplit={handleLegSplitShow}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleClose(true, null, null);
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleClose(false, trade, removedLegs);
              }}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
        {splitLegDialogOpen && (
          <AdmisTradeLegSplitDialog
            open={splitLegDialogOpen}
            handleClose={splitLegCloseHandler}
            handleChange={splitLegQuantityChangeHandler}
          />
        )}
      </div>
    );
  }
};
export default AdmisTradeEditDialog;
