import AdmisTradeGrid from "./AdmisTradeGrid.js";
import { useCallback } from "react";
import {
  unignoreAdmisTrade,
  fetchIgnoredAdmisTrades,
} from "../../store/admisTradeListSlice";
import {
  unignoreTapTrade,
  fetchIgnoredTapTrades,
} from "../../store/tapTradeListSlice";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import SearchFilters from "../ui/SearchFilters";
import Box from "@mui/material/Box";
import { parseISO } from "date-fns";
import TapTradeGrid from "./TapTradeGrid.js";

const IgnoredTradeList = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const ignoredAdmisTrades = useSelector(
    (state) => state.admisTradeList.ignoredTrades
  );
  const ignoredTapTrades = useSelector(
    (state) => state.tapTradeList.ignoredTrades
  );  
  const searchFilters = useSelector(
    (state) => state.admisTradeList.ignoredTradeFilters
  );

  const UningnoreAdmisTradeHandler = useCallback(async (trade) => {
    const token = await getAccessTokenSilently();
    dispatch(unignoreAdmisTrade({ token: token, admisTrade: trade }));
  }, [dispatch, getAccessTokenSilently]);

  const UningnoreTapTradeHandler = useCallback(async (trade) => {
    const token = await getAccessTokenSilently();
    dispatch(unignoreTapTrade({ token: token, tapTrade: trade }));
  }, [dispatch, getAccessTokenSilently]);

  const runSearchHandler = useCallback(
    async (filters) => {
      const { dateFrom, dateTo } = filters;
      const token = await getAccessTokenSilently();
      dispatch(
        fetchIgnoredAdmisTrades({
          token: token,
          dateFrom: dateFrom,
          dateTo: dateTo,
        })
      );
      dispatch(
        fetchIgnoredTapTrades({
          token: token,
          dateFrom: dateFrom,
          dateTo: dateTo,
        })
      );      
    },
    [dispatch, getAccessTokenSilently]
  );

  return (
    <Box>
      <Box sx={{ marginBottom: 1 }}>
        <SearchFilters
          onFilterChange={runSearchHandler}
          initialDateFrom={parseISO(searchFilters.dateFrom)}
          initialDateTo={parseISO(searchFilters.dateTo)}
        />
      </Box>
      <AdmisTradeGrid
        admisTradeRows={ignoredAdmisTrades}
        showMultiActions={false}
        showLineActions={true}
        showHeadersAndFooters={false}
        showTradeDetailIcon={false}
        onUnignoreTrade={UningnoreAdmisTradeHandler}
      />
      <br/>
      <TapTradeGrid
        showLineActions={true}
        showRevenue={false}
        showTradeDetailIcon={false}
        showHeadersAndFooters={false}
        showTags={false}
        rows={ignoredTapTrades}
        onUnignoreTrade={UningnoreTapTradeHandler}
      />      
    </Box>
  );
};

export default IgnoredTradeList;
