import Button from "@mui/material/Button";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";

const GroupTradesButton = (props) => {
  const { onClick } = props;
  return (
    <Button
      variant="outlined"
      component="span"
      startIcon={<PlaylistAddIcon />}
      size="small"
      sx={{ marginBottom: 1 }}
      onClick={onClick}
    >
      GROUP
    </Button>
  );
};

export default GroupTradesButton;
