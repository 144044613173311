import AdmisTradeGrid from "./AdmisTradeGrid.js";
import { useState, useCallback } from "react";
import {
  importAdmisTrades,
  completeAdmisTrade,
  ignoreAdmisTrade,
  fetchOpenAdmisTrades,
  groupAdmisTrades,
  updateAdmisTrade,
} from "../../store/admisTradeListSlice";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import AdmisTradeEditDialog from "./AdmisTradeEditDialog.js";

const AdmisTradeList = () => {
  const admisTradeRows = useSelector((state) => state.admisTradeList.items);

  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const [selectedTrade, setSelectedTrade] = useState({});
  const [tradeDetailDialogOpen, setTradeDetailDialogOpen] = useState(false);

  const showTradeDetailHandler = (trade) => {
    setSelectedTrade(trade);
    setTradeDetailDialogOpen(true);
  };

  const tradeDetailDialogCloseHandler = async (cancel, editedTrade, removedLegs) => {
    // only update if the user had not cancelled and something has changed
    if (
      !cancel &&
      (selectedTrade.legs.length !== editedTrade.legs.length ||
        selectedTrade.quantity !== editedTrade.quantity)
    ) {
      const token = await getAccessTokenSilently();
      dispatch(updateAdmisTrade({ token: token, trade: editedTrade, removedLegs: removedLegs }));
    }
    setTradeDetailDialogOpen(false);
  };

  const completeTradeHandler = useCallback(
    async (trade) => {
      const token = await getAccessTokenSilently();
      dispatch(completeAdmisTrade({ token: token, admisTrade: trade }));
    },
    [dispatch, getAccessTokenSilently]
  );

  const ignoreTradeHandler = useCallback(
    async (trade) => {
      const token = await getAccessTokenSilently();
      dispatch(ignoreAdmisTrade({ token: token, admisTrade: trade }));
      setTradeDetailDialogOpen(false);
    },
    [dispatch, getAccessTokenSilently]
  );

  const refreshTradesHandler = useCallback(async () => {
    const token = await getAccessTokenSilently();
    dispatch(fetchOpenAdmisTrades({ token: token }));
  }, [getAccessTokenSilently, dispatch]);

  const groupTradesHandler = useCallback(
    async (selectedTrades) => {
      const token = await getAccessTokenSilently();
      dispatch(groupAdmisTrades({ token: token, admisTrades: selectedTrades }));
    },
    [dispatch, getAccessTokenSilently]
  );

  const fileUploadHandler = useCallback(
    async (file) => {
      if (file.name.length > 0) {
        const token = await getAccessTokenSilently();
        const fileReader = new FileReader();
        fileReader.readAsText(file, "UTF-8");
        fileReader.onload = function (evt) {
          dispatch(
            importAdmisTrades({ token: token, admisFile: evt.target.result })
          );
        };
        fileReader.onerror = function (evt) {
          console.log("error: " + evt);
        };
      }
    },
    [dispatch, getAccessTokenSilently]
  );

  return (
    <div>
      <AdmisTradeGrid
        onClickTrade={showTradeDetailHandler}
        admisTradeRows={admisTradeRows}
        onCompleteTrade={completeTradeHandler}
        onRefreshTrades={refreshTradesHandler}
        onGroupTrades={groupTradesHandler}
        onUploadFile={fileUploadHandler}
        showMultiActions={true}
        showLineActions={true}
        showHeadersAndFooters={true}
        showTradeDetailIcon={true}
      />
      {tradeDetailDialogOpen && (
        <AdmisTradeEditDialog
          open={tradeDetailDialogOpen}
          selectedTradeId={selectedTrade.admisTradeId}
          handleClose={tradeDetailDialogCloseHandler}
          handleIgnore={ignoreTradeHandler}          
        />
      )}
    </div>
  );
};

export default AdmisTradeList;
