import { green, grey, blue, orange } from "@mui/material/colors";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import NavCard from "../ui/NavCard";

const MainNav = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} justifyContent="center">
        <Grid xs={7} sm={4}>
          <NavCard
            to="/trades"
            label="trades"
            title="Trades"
            text="This is where we go to manage our trades"
            avatarText="T"
            avatarStyle={{ bgcolor: green[500] }}
          />
        </Grid>
        <Grid xs={7} sm={4}>
          <NavCard
            to="/signals"
            label="signals"
            title="Signals"
            text="This is where we go to manage our signals"
            avatarText="S"
            avatarStyle={{ bgcolor: blue[500] }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} justifyContent="center">
        <Grid xs={7} sm={4}>
          <NavCard
            to="/user/settings"
            label="settings"
            title="User Settings"
            text="This is where we go to manage our user settings"
            avatarText="U"
            avatarStyle={{ bgcolor: orange[500] }}
          />
        </Grid>
        <Grid xs={7} sm={4}>
          <NavCard
            label="nothing"
            title="Absolutely nothing"
            text="This is where we go to do absolutely nothing"
            avatarText="N"
            avatarStyle={{ bgcolor: grey[500] }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default MainNav;
