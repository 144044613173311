import { DataGridPremium } from "@mui/x-data-grid-premium";
import Box from "@mui/material/Box";
import { format, parseISO } from "date-fns";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import IconButton from "@mui/material/IconButton";

const TapTradeLegGrid = (props) => {
  const { trade, onUngroupTrade, showHeadersAndFooters, showLineActions } = props;

  const renderActions = (params) => {
    if (trade.legs?.length > 1 && showLineActions) {
      return (
        <Box>
          <IconButton
            size="small"
            edge="start"
            aria-label="ungroup trade"
            onClick={() => {
              onUngroupTrade(params.row);
            }}
          >
            <PlaylistRemoveIcon />
          </IconButton>
        </Box>
      );  
    }
  };

  const columns = [
    {
      field: "tradeDate",
      headerName: "Trade Date",
      flex: 0.25,
      type: "date",
      valueFormatter: (params) => {
        return format(parseISO(params.value), "dd/MM/yyyy");
      },
    },
    {
      field: "tradeEntryTime",
      headerName: "Entry Time",
      flex: 0.25,
      type: "dateTime",
      valueFormatter: (params) => {
        return format(parseISO(params.value), "HH:mm:ss");
      },
    },
    {
      field: "contractDescription",
      headerName: "Contract",
      flex: 0.5,
      type: "string",
    },
    {
      field: "product",
      headerName: "Product",
      flex: 0.5,
      type: "string",
      valueGetter: (params) => {
        return params.value.description;
      },
    },
    {
      field: "contractDate",
      headerName: "Period",
      flex: 0.25,
      type: "string",
      valueGetter: (params) => {
        // value getter rather than formatter here so the filter works on the column text rather than the date
        return format(parseISO(params.value), "LLL yyyy");
      },
    },
    {
      field: "lastTradingDate",
      headerName: "Last Trading Date",
      flex: 0.25,
      type: "date",
      valueFormatter: (params) => {
        return format(parseISO(params.value), "dd/MM/yyyy");
      },
    },
    { field: "quantity", headerName: "Qty", flex: 0.25, type: "number" },
    {
      field: "price",
      headerName: "Price",
      flex: 0.25,
      type: "number",
      valueFormatter: (params) => {
        return (Math.round(params.value * 10000) / 10000).toFixed(4);
      },
    },
    {
      field: "tapUser",
      headerName: "User",
      flex: 0.5,
      type: "string",
      valueGetter: (params) => {
        return params.value.fullName;
      },
    },
  ];

  if (showLineActions) {
    columns.push({
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: renderActions,
    });  
  }

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <DataGridPremium
        initialState={{
          sorting: {
            sortModel: [{ field: "tradeEntryTime", sort: "asc" }],
          },
        }}
        rowHeight={30}
        autoHeight
        rows={trade.legs}
        columns={columns}
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        getRowId={(row) => row.tapTradeLegId}
        hideFooter={!showHeadersAndFooters}
      />
    </Box>
  );
};

export default TapTradeLegGrid;
