import Snackbar from "@mui/material/Snackbar";
import { useDispatch, useSelector } from "react-redux";
import { notificationActions } from "../../store/notificationSlice";
import Alert from "@mui/material/Alert";

const NotificationFooter = () => {
  const text = useSelector((state) => state.notifications.text);
  const severity = useSelector((state) => state.notifications.severity);
  const open = useSelector((state) => state.notifications.isOpen);
  const autoHideDuration = useSelector((state) => state.notifications.autoHideDuration);
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(notificationActions.closeMessage());
  };

  if (text !== "") {
    return (
      <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={handleClose}>
        <Alert severity={severity} onClose={handleClose}>
          {text}
        </Alert>
      </Snackbar>
    );
  }
};

export default NotificationFooter;
