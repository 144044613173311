import { createSlice } from "@reduxjs/toolkit";

const dialogSlice = createSlice({
  name: "dialog",
  initialState: {
    title: "", 
    text: "", 
    open: false
  },
  reducers: {
    showDialog(state, action) {
      const { title, text } = action.payload;
      state.title = title;
      state.text = text;
      state.open = true;
    },
    closeDialog(state, action) {
      state.open = false;      
    }
  },
});

export const { showDialog, closeDialog } = dialogSlice.actions;
export default dialogSlice;
