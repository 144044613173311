import { useEffect, useState, Fragment, useCallback } from "react";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import Box from "@mui/material/Box";
import RefreshButton from "../ui/RefreshButton";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

const SearchFilters = (props) => {
  const { onFilterChange, initialDateFrom, initialDateTo } = props;
  const [value, setValue] = useState([initialDateFrom, initialDateTo]);

  const refreshResults = useCallback (() => {
    onFilterChange({ dateFrom: value[0], dateTo: value[1] });    
  },[onFilterChange, value])

  useEffect(() => {
    if (value[0] !== null && value[1] !== null) {
      refreshResults()
    }
  }, [refreshResults, value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid2 container sx={{ flexGrow: 1 }}>
        <Grid2 container sx={{ flexGrow: 1 }}>
          <DateRangePicker
            inputFormat="dd/MM/yyyy"
            value={value}
            onChange={(newValue) => {
              setValue(newValue);
            }}
            renderInput={(startProps, endProps) => (
              <Fragment>
                <TextField ref={startProps.inputRef} {...startProps.inputProps} size="small" inputProps= {{ style: { height: "15px" }}}/>
                <Box sx={{ mx: 1 }}> to </Box>
                <TextField ref={endProps.inputRef} {...endProps.inputProps} size="small" inputProps= {{ style: { height: "15px" }}}/>
              </Fragment>
            )}
          />
        </Grid2>
        <Grid2>
          <RefreshButton onClick={refreshResults}/>
        </Grid2>
      </Grid2>
    </LocalizationProvider>
  );
};

export default SearchFilters;
