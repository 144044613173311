import { createAsyncThunk, createSlice, createSelector } from "@reduxjs/toolkit";
import API from "../api/api";
import { format } from "date-fns";

const admisTradeListSlice = createSlice({
  name: "admisTradeList",
  initialState: { 
    items: [], 
    ignoredTrades: [],
    ignoredTradeFilters: { dateFrom: new Date().toISOString(), dateTo: new Date().toISOString() },
  },
  reducers: {
    updateTrades(state, action) {
      let { trades } = action.payload;
      trades = Array.isArray(trades) ? trades : [trades];
      updateTradesInState(state, trades);
    },    
    removeTrades(state, action) {
      let { trades } = action.payload;
      trades = Array.isArray(trades) ? trades : [trades]; // the user can pass an individual trade or an array of trades 
      trades.forEach(trade => {
        let index = state.items.findIndex((i) => i.admisTradeId === trade.admisTradeId);
        if (index > -1) {
          state.items.splice(index, 1);
        }        
      });
    },
    addTrades(state, action) {
      let { trades } = action.payload;
      trades = Array.isArray(trades) ? trades : [trades];
      const updatedIds = trades.map((r) => r.admisTradeId);

      // remove any old versions of the trades from state and add the new ones in
      let updatedState = [...state.items.filter((r) => !updatedIds.includes(r.admisTradeId))];
      updatedState = updatedState.concat(trades);

      state.items = updatedState;
    },
  },
  extraReducers(builder) {
    builder
      /***** Importing new admis trades *****/
      .addCase(importAdmisTrades.pending, (state, action) => {
        // set a pending status on the state so we show the loader
      })
      .addCase(importAdmisTrades.fulfilled, (state, action) => {
        // state update handled in the signalr response
      })
      .addCase(importAdmisTrades.rejected, (state, action) => {
        // set an error status on the state so we show the error
      })

      /***** Fetching open admis trades *****/
      .addCase(fetchOpenAdmisTrades.pending, (state, action) => {
        // set a pending status on the state so we show the loader
      })
      .addCase(fetchOpenAdmisTrades.fulfilled, (state, action) => {
        const { trades } = action.payload;
        state.items = trades;
      })
      .addCase(fetchOpenAdmisTrades.rejected, (state, action) => {
        // set an error status on the state so we show the error
      })

      /***** Fetching ignored admis trades *****/
      .addCase(fetchIgnoredAdmisTrades.pending, (state, action) => {
        // set a pending status on the state so we show the loader
      })
      .addCase(fetchIgnoredAdmisTrades.fulfilled, (state, action) => {
        state.ignoredTrades = action.payload.trades;
        state.ignoredTradeFilters.dateFrom = action.payload.dateFrom;
        state.ignoredTradeFilters.dateTo = action.payload.dateTo;
      })
      .addCase(fetchIgnoredAdmisTrades.rejected, (state, action) => {
        // set an error status on the state so we show the error
      })

      /***** Grouping admis trades *****/
      .addCase(groupAdmisTrades.pending, (state, action) => {
        // set a pending status on the state so we show the loader
      })
      .addCase(groupAdmisTrades.fulfilled, (state, action) => {
        // state updated handled in signalr hub method
      })
      .addCase(groupAdmisTrades.rejected, (state, action) => {
        // set an error status on the state so we show the error
      })

      /***** Completing admis trades *****/
      .addCase(completeAdmisTrade.pending, (state, action) => {
        // set a pending status on the state so we show the loader
      })
      .addCase(completeAdmisTrade.fulfilled, (state, action) => {
        // handled by the hub
      })
      .addCase(completeAdmisTrade.rejected, (state, action) => {
        // set an error status on the state so we show the error
      })

      /***** Ignoring admis trades *****/
      .addCase(ignoreAdmisTrade.pending, (state, action) => {
        // set a pending status on the state so we show the loader
      })
      .addCase(ignoreAdmisTrade.fulfilled, (state, action) => {
        // handled by the hub
      })
      .addCase(ignoreAdmisTrade.rejected, (state, action) => {
        // set an error status on the state so we show the error
      })

      /***** Unignoring admis trades *****/
      .addCase(unignoreAdmisTrade.pending, (state, action) => {
        // set a pending status on the state so we show the loader
      })
      .addCase(unignoreAdmisTrade.fulfilled, (state, action) => {
        // handled by the hub
      })
      .addCase(unignoreAdmisTrade.rejected, (state, action) => {
        // set an error status on the state so we show the error
      })

      /***** Updating trades admis trade legs *****/
      .addCase(updateAdmisTrade.pending, (state, action) => {
        // set a pending status on the state so we show the loader
      })
      .addCase(updateAdmisTrade.fulfilled, (state, action) => {
        // handled by signalr hub
      })
      .addCase(updateAdmisTrade.rejected, (state, action) => {
        // set an error status on the state so we show the error
      });      
  },
});

// private helpers to update a given trade in the correct state collection
const updateTradesInState = (state, updatedTrades) => {
  updatedTrades.forEach((trade) => {
    removeTradeFromAllState(state, trade);
    addTradeToState(state, trade);
  });
};

const removeTradeFromAllState = (state, trade) => {
  removeTradeFromState(trade, state.items);
  removeTradeFromState(trade, state.ignoredTrades);
};

const removeTradeFromState = (trade, collection) => {
  const index = collection.findIndex((i) => i.admisTradeId === trade.admisTradeId);
  if (index > -1) {
    collection.splice(index, 1);
  }
};

const addTradeToState = (state, trade) => {
  // there are no search criteria on open
  if (trade.isOpen) {
    state.items.push(trade);
  }

  // only add to ignored if the trade matches the ignored search criteria
  if (trade.isIgnored) {
    state.ignoredTrades.push(trade);
  }
};

// fetch open trades
// input is { token }
// output is { trades }
export const fetchOpenAdmisTrades = createAsyncThunk("admisTrades/fetchOpenTrades", async (payload) => {
  const { token } = payload;
  const config = { headers: { Authorization: `Bearer ${token}` } };

  const response = await API.get("AdmisTradeOpen", config);
  return { trades: response.data };
});

// fetch ignored trades
// input is { token, dateFrom, dateTo }
// output is { trades, dateFrom, dateTo }
export const fetchIgnoredAdmisTrades = createAsyncThunk("admisTrades/fetchIgnoredTrades", async (payload) => {
  const { token, dateFrom, dateTo } = payload;

  const formattedDateFrom = format(dateFrom, "dd/MMM/yyyy");
  const formattedDateTo = format(dateTo, "dd/MMM/yyyy");  
  const config = { 
    headers: { Authorization: `Bearer ${token}` },
    params: { dateFrom: formattedDateFrom, dateTo: formattedDateTo }, 
  };

  const response = await API.get("AdmisTradeIgnored", config);
  return {
    trades: response.data,
    dateFrom: new Date(dateFrom).toISOString(),
    dateTo: new Date(dateTo).toISOString(),
  };
});

// import trades
// input is { token, admisFile }
// output is { trades }
export const importAdmisTrades = createAsyncThunk("admisTrades/importTrades", async (payload) => {
  const { token, admisFile } = payload;
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const postedData = { admisFile: admisFile };

  const response = await API.post("AdmisTradeOpen", postedData, config);
  return { trades: response.data };
});

// group trades
// input is { token, admisTrades }
// output is { oldTrades, newTrades }
export const groupAdmisTrades = createAsyncThunk("admisTrades/groupTrades", async (payload) => {
  const { token, admisTrades } = payload;
  const config = { headers: { Authorization: `Bearer ${token}` } };

  const response = await API.put("AdmisTradeGrouped", admisTrades, config);
  return { oldTrades: admisTrades, newTrades: response.data };
});

// complete a trade
// input is { token, admisTrade }
// output is { trade }
export const completeAdmisTrade = createAsyncThunk("admisTrades/completeTrade", async (payload) => {
  const { token, admisTrade } = payload;
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const postedData = { ...admisTrade };

  const response = await API.post("TapTrade", postedData, config);
  const tapTrade = response.data;

  if (tapTrade != null) {
    return { trade: tapTrade };
  }

  return { trade: admisTrade };
});

// ignore a trade
// input is { token, admisTrade }
// output is { trade }
export const ignoreAdmisTrade = createAsyncThunk("admisTrades/ignoreTrade", async (payload) => {
  const { token, admisTrade } = payload;
  const postedData = { ...admisTrade };
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const response = await API.post("AdmisTradeIgnored", postedData, config);
  return { trades: response.data };
});

// unignore a trade
// input is { token, admisTrade }
// output is { trade }
export const unignoreAdmisTrade = createAsyncThunk("admisTrades/unignoreTrade", async (payload) => {
  const { token, admisTrade } = payload;
  const postedData = { ...admisTrade };
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const response = await API.put("AdmisTradeIgnored", postedData, config);
  return { trades: response.data };
});

// update a trade
// input is { token, trade, removedLegs }
// output is { trade }
export const updateAdmisTrade = createAsyncThunk("admisTrades/updateTrade", async (payload) => {
  const { token, trade, removedLegs } = payload;
  const postedData = { trade: trade, removedLegs: removedLegs };
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const response = await API.put("AdmisTradeOpen", postedData, config);
  return { trades: response.data };
});

export const selectAllTrades = (state) =>
  state.admisTradeList.items;

// momoized selector for getting a single trade from the state collection
export const selectTradeById = createSelector(
  [selectAllTrades, (state, tradeId) => tradeId],
  (items, tradeId) => items.filter((trade) => trade.admisTradeId === tradeId)[0]
);

export const admisTradeListActions = admisTradeListSlice.actions;
export default admisTradeListSlice;
