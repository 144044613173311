import {
  DataGridPremium,
  useKeepGroupedColumnsHidden,
  useGridApiRef,
  GridToolbar,
} from "@mui/x-data-grid-premium";
import Box from "@mui/material/Box";
import { format, parseISO } from "date-fns";
import { useState } from "react";
import GroupTradesButton from "../ui/GroupTradesButton";
import RefreshButton from "../ui/RefreshButton";
import InfoIcon from "@mui/icons-material/Info";
import TagIcon from "@mui/icons-material/Tag";
import IconButton from "@mui/material/IconButton";
import DoneIcon from "@mui/icons-material/Done";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import clsx from "clsx";
import { Tooltip } from "@mui/material";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";

const TapTradeGrid = (props) => {
  const {
    rows,
    onShowTradeDetail,
    onGroupTrade,
    onEditTrade,
    onCompleteTrade,
    onRefreshTrades,
    onSplitTrade,
    onUnignoreTrade,

    showRevenue,
    showLineActions,
    showMultiActions,
    showHeadersAndFooters,
    showTags,
    showTradeDetailIcon,
  } = props;
  const [selected, setSelected] = useState([]);
  const apiRef = useGridApiRef();

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      pagination: {
        pageSize: 20,
      },
      sorting: {
        sortModel: [
          { field: "tradeDate", sort: "asc" },
          { field: "tradeExitTime", sort: "asc" },
        ],
      },
      aggregation: {
        model: {
          revenue: "sum",
          buyQuantity: "sum",
          sellQuantity: "sum",
          totalQuantity: "sum",
        },
      },
    },
  });

  const renderActions = (params) => {
    // hide the actions if we have no tradeId (grid footers, grouped rows etc)
    if (params.row?.tapTradeId == null) {
      return "";
    }
    return (
      <Box>
        {showTradeDetailIcon && (
          <Tooltip title="Show detail">
            <IconButton
              size="small"
              edge="start"
              aria-label="show trade detail"
              onClick={() => {
                onShowTradeDetail(params.row);
              }}
            >
              <InfoIcon />
            </IconButton>
          </Tooltip>
        )}
        {showTradeDetailIcon && (
          <Tooltip title="Edit">
            <IconButton
              size="small"
              edge="start"
              aria-label="edit trade"
              onClick={() => {
                onEditTrade(params.row);
              }}
              fontSize="10px"
            >
              <TagIcon />
            </IconButton>
          </Tooltip>
        )}
        {params.row.canSplit && (
          <Tooltip title="Split">
            <IconButton
              size="small"
              edge="start"
              aria-label="split trade"
              onClick={() => {
                onSplitTrade(params.row);
              }}
            >
              <CallSplitIcon />
            </IconButton>
          </Tooltip>
        )}
        {params.row.canComplete && (
          <Tooltip title="Complete">
            <IconButton
              size="small"
              edge="start"
              aria-label="mark as complete"
              onClick={() => {
                onCompleteTrade(params.row);
              }}
            >
              <DoneIcon />
            </IconButton>
          </Tooltip>
        )}
        {params.row.canUnignore && (
          <Tooltip title="Restore">
            <IconButton
              size="small"
              edge="start"
              aria-label="restore to tap trades screen"
              onClick={() => {
                onUnignoreTrade(params.row);
              }}
            >
              <RestoreFromTrashIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    );
  };

  const columns = [
    {
      field: "tapTradeId",
      headerName: "Id",
      flex: 0.1,
      aggregable: false,
      type: "number",
    },
    {
      field: "latestTradeDate",
      headerName: "Exit Date",
      flex: 0.25,
      aggregable: false,
      type: "date",
      valueFormatter: (params) => {
        if (params.value) {
          return format(params.value, "dd/MM/yyyy");
        }
      },
      valueGetter: (params) => {
        if (params.value) {
          return parseISO(params.value);
        }
      },
    },
    {
      field: "tradeExitTime",
      headerName: "Exit Time",
      flex: 0.25,
      aggregable: false,
      type: "dateTime",
      valueFormatter: (params) => {
        if (params.value) {
          return format(params.value, "HH:mm:ss");
        }
      },
      valueGetter: (params) => {
        if (params.value) {
          return parseISO(params.value);
        }
      },
    },
    {
      field: "contractDescription",
      headerName: "Contract",
      aggregable: false,
      flex: 0.5,
      type: "string",
    },
    {
      field: "productDescription",
      headerName: "Product",
      aggregable: false,
      flex: 0.5,
      type: "string",
      valueGetter: (params) => {
        // prioritise rendering the strategy description if we have one
        if (params.row.strategyDescription) {
          return params.row.strategyDescription;
        }
        return params.value;
      },
    },
    { field: "period", headerName: "Period", aggregable: false, flex: 0.5 },
    {
      field: "buyQuantity",
      headerName: "Bought",
      type: "number",
      flex: 0.25,
      valueGetter: (params) => {
        // we render the strategy quantity if we have one else we use the normal leg price
        let quantityValue = params.value;
        if (params.row.buyQuantityStrategy != null) {
          quantityValue = params.row.buyQuantityStrategy;
        }
        return quantityValue;
      },
    },
    {
      field: "buyPrice",
      headerName: "Buy Price",
      type: "number",
      aggregable: false,
      flex: 0.25,
      valueGetter: (params) => {
        // this always renders in the aggregate footer unless explicitly stopped
        // using renderCell and checking for a real row
        if (params.row?.tapTradeId == null) {
          return "";
        }
        // we render the strategy price if we have one else we use the normal leg price
        let priceValue = params.value;
        if (params.row.buyPriceStrategy != null) {
          priceValue = params.row.buyPriceStrategy;
        }
        return (Math.round(priceValue * 10000) / 10000).toFixed(4).toString();
      },
    },
    {
      field: "sellQuantity",
      headerName: "Sold",
      type: "number",
      flex: 0.25,
      valueGetter: (params) => {
        // we render the strategy quantity if we have one else we use the normal leg price
        let quantityValue = params.value;
        if (params.row.sellQuantityStrategy != null) {
          quantityValue = params.row.sellQuantityStrategy;
        }
        return quantityValue;
      },
    },
    {
      field: "sellPrice",
      headerName: "Sell Price",
      type: "number",
      aggregable: false,
      flex: 0.25,
      valueGetter: (params) => {
        // this always renders in the aggregate footer unless explicitly stopped
        // using renderCell and checking for a real row
        if (params.row?.tapTradeId == null) {
          return "";
        }
        // we render the strategy price if we have one else we use the normal leg price
        let priceValue = params.value;
        if (params.row.sellPriceStrategy != null) {
          priceValue = params.row.sellPriceStrategy;
        }
        return (Math.round(priceValue * 10000) / 10000).toFixed(4).toString();
      },
    },
  ];

  // show the calculated total quantity only when revenue is hidden
  // if we have revenue the trade is complete so calculated quantity is always 0
  columns.push({
    field: "totalQuantity",
    headerName: "Qty",
    type: "number",
    flex: 0.25,
    cellClassName: (params) => {
      if (params.value == null) {
        return "";
      }

      return clsx("super-app", {
        negative: params.value < 0,
        positive: params.value > 0,
        zero: params.value === 0,
      });
    },
  });

  columns.push(
    {
      field: "users",
      headerName: "User",
      aggregable: false,
      flex: 0.25,
      type: "string",
    },
    {
      field: "noLegs",
      headerName: "Legs",
      type: "number",
      aggregable: false,
      flex: 0.125,
    }
  );
  if (showTags) {
    columns.push(
      {
        field: "tagName",
        headerName: "Tag",
        aggregable: false,
        type: "string",
        flex: 0.25,
      },
      {
        field: "signalId",
        headerName: "Signal",
        flex: 0.1,
        aggregable: false,
        type: "number",
      }
    );
  }

  if (showRevenue) {
    columns.push({
      field: "revenue",
      headerName: "Revenue",
      type: "number",
      flex: 0.25,
      valueFormatter: (params) => {
        return (Math.round(params.value * 10000) / 10000).toFixed(2);
      },
    });
  }

  if (showLineActions) {
    columns.push({
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: renderActions,
      aggregable: false,
    });
  }

  return (
    <Box>
      {showMultiActions && (
        <Grid2 container sx={{ flexGrow: 1 }}>
          <Grid2>
            <GroupTradesButton
              onClick={() => {
                onGroupTrade(rows.filter((r) => selected.includes(r.tapTradeId)));
              }}
            />
          </Grid2>
          <Grid2 mdOffset="auto">
            <RefreshButton onClick={onRefreshTrades} />
          </Grid2>
        </Grid2>
      )}
      <Box
        sx={{
          height: "100%",
          width: "100%",
          "& .super-app.zero": {
            color: "info.dark",
            fontWeight: "600",
          },
          "& .super-app.negative": {
            color: "error.dark",
            fontWeight: "600",
          },
          "& .super-app.positive": {
            color: "success.dark",
            fontWeight: "600",
          },
        }}
      >
        <DataGridPremium
          apiRef={apiRef}
          initialState={initialState}
          rowHeight={30}
          autoHeight
          rows={rows}
          columns={columns}
          rowsPerPageOptions={[5, 10, 20, 100]}
          checkboxSelection={showMultiActions}
          disableSelectionOnClick
          experimentalFeatures={{
            newEditingApi: true,
            aggregation: showHeadersAndFooters,
          }}
          getRowId={(row) => row.tapTradeId}
          onSelectionModelChange={(itm) => setSelected(itm)}
          selectionModel={selected}
          components={{ Toolbar: showHeadersAndFooters ? GridToolbar : null }}
          hideFooter={!showHeadersAndFooters}
        />
      </Box>
    </Box>
  );
};

export default TapTradeGrid;
