import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';

const PageLoader = () => {
  return(
    <Box display="flex" justifyContent="center" alignItems="center" borderTop="100px" sx={{ m: 10 }}>
      <CircularProgress color="primary" size="10rem"/>
    </Box>    
  )
};

export default PageLoader;