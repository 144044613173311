import { DataGridPremium } from "@mui/x-data-grid-premium";
import Box from "@mui/material/Box";
import { format, parseISO } from "date-fns";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import IconButton from "@mui/material/IconButton";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import { memo } from "react";

const AdmisTradeLegGrid = memo((props) => {
  const { rows, onUngroupTrade, onShowLegSplit, showHeadersAndFooters } = props;

  const renderActions = (params) => {
    return (
      <Box>
        <IconButton
          size="small"
          edge="start"
          aria-label="ungroup trade"
          onClick={() => {
            onUngroupTrade(params.row);
          }}
        >
          <PlaylistRemoveIcon />
        </IconButton>
        <IconButton
          size="small"
          edge="start"
          aria-label="split trade"
          onClick={() => {
            onShowLegSplit(params.row);
          }}
        >
          <CallSplitIcon />
        </IconButton>
      </Box>
    );
  };

  const columns = [
    {
      field: "tradeDate",
      headerName: "Trade Date",
      flex: 0.5,
      type: "date",
      valueFormatter: (params) => {
        return format(parseISO(params.value), "dd/MM/yyyy");
      },
    },
    {
      field: "tradeEntryTime",
      headerName: "Entry Time",
      flex: 0.5,
      type: "dateTime",
      valueFormatter: (params) => {
        return format(parseISO(params.value), "HH:mm:ss");
      },
    },
    { field: "contractDescription", headerName: "Contract", flex: 1, type: "string" },
    {
      field: "product",
      headerName: "Product",
      flex: 0.5,
      type: "string",
      valueGetter: (params) => {
        return params.value.description;
      },
    },
    {
      field: "contractDate",
      headerName: "Period",
      flex: 0.5,
      type: "string",
      valueGetter: (params) => {
        return format(parseISO(params.value), "LLL yyyy");
      },
    },
    {
      field: "lastTradingDate",
      headerName: "Last Trading Date",
      flex: 0.5,
      type: "date",
      valueFormatter: (params) => {
        return format(parseISO(params.value), "dd/MM/yyyy");
      },
    },
    { field: "quantity", headerName: "Qty", flex: 0.25, type: "number" },
    {
      field: "price",
      headerName: "Price",
      flex: 0.25,
      type: "number",
      valueFormatter: (params) => {
        return (Math.round(params.value * 10000) / 10000).toFixed(4);
      },
    },
    {
      field: "tapUser",
      headerName: "User",
      flex: 0.5,
      type: "string",
      valueGetter: (params) => {
        return params.value.fullName;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: renderActions,
    },
  ];

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <DataGridPremium
        initialState={{
          pagination: {
            pageSize: 20,
          },
          sorting: {
            sortModel: [{ field: "tradeEntryTime", sort: "asc" }],
          },
        }}
        rowHeight={30}
        autoHeight
        rows={rows}
        columns={columns}
        rowsPerPageOptions={[5, 10, 20, 100]}
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        getRowId={(row) => row.admisTradeLegId}
        hideFooter={!showHeadersAndFooters}
      />
    </Box>
  );
});

export default AdmisTradeLegGrid;
