import TapTradeGrid from "./TapTradeGrid";
import { useSelector } from "react-redux";
import { useState, useCallback } from "react";
import Box from "@mui/material/Box";
import {
  fetchOpenTrades,
  fetchCompleteTrades,
  fetchTradeSignal,
  updateTrade,
  groupTrades,
  splitTrade,
  completeTrade,
  ignoreTapTrade
} from "../../store/tapTradeListSlice";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { showDialog } from "../../store/dialogSlice";
import TapTradeEditDialog from "./TapTradeEditDialog";
import TapTradeDetailDialog from "./TapTradeDetailDialog";
import SearchFilters from "../ui/SearchFilters";
import { parseISO } from "date-fns";

const TapTradeList = (props) => {
  const { searchMode } = props;
  const openTrades = useSelector((state) => state.tapTradeList.openTrades);
  const closedTrades = useSelector((state) => state.tapTradeList.closedTrades);
  const [closeAfterTagging, setCloseAfterTagging] = useState(false);
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const searchFilters = useSelector((state) => state.tapTradeList.closedTradeFilters);

  const [selectedTrade, setSelectedTrade] = useState({});
  const [tradeEditDialogOpen, setTradeEditDialogOpen] = useState(false);
  const [tradeDetailDialogOpen, setTradeDetailDialogOpen] = useState(false);

  const groupTradesHandler = async (selectedTrades) => {
    // check that the trades all have the same tag or one actual tag and some empty ones
    const tags = selectedTrades.filter((t) => t.tag != null).map((t) => t.tag?.id);
    const uniqueTags = [...new Set(tags)];

    const signals = selectedTrades.filter((t) => t.signal != null).map((t) => t.signal?.signalId);
    const uniqueSignals = [...new Set(signals)];

    if (uniqueTags.length > 1 || uniqueSignals.length > 1) {
      dispatch(
        showDialog({
          title: "Trade grouping",
          text: "The selected trades have multiple different tags and/or signals. Please ensure all trades have the same details before grouping",
        })
      );
    } else {
      const token = await getAccessTokenSilently();
      dispatch(groupTrades({ token: token, trades: selectedTrades }));
    }
  };

  const splitTradeHandler = async (trade) => {
    const token = await getAccessTokenSilently();
    dispatch(splitTrade({ token: token, trade: trade }));
  };

  const tradeEditCloseHandler = async (cancel, editedTrade) => {
    // only update if the user has not cancelled and the tag, signal or legs have changed
    if (
      !cancel &&
      (selectedTrade.tag?.id !== editedTrade.tag?.id ||
        selectedTrade.signal?.signalId !== editedTrade.signal?.signalId ||
        selectedTrade.legs.length !== editedTrade.legs.length)
    ) {
      const token = await getAccessTokenSilently();

      dispatch(updateTrade({ token: token, trade: editedTrade }));

      if (closeAfterTagging && editedTrade.tag) {
        completeTradeHandler(editedTrade);
      }
    }

    setTradeEditDialogOpen(false);
    setCloseAfterTagging(false);
  };

  const tradeEditClickHandler = async (trade) => {
    setSelectedTrade(trade);
    setTradeEditDialogOpen(true);
  };

  const completeTradeHandler = async (trade) => {
    // if the trade has no tag it cannot be closed - show the tag screen and set it so we will close the trade after the tag has been added
    if (!trade.tag) {
      setCloseAfterTagging(true);
      tradeEditClickHandler(trade);
    } else {
      // the trade has a tag so complete it. If the tag and/opr signal have changed then the new values will be saved as part of the complete process
      const token = await getAccessTokenSilently();
      dispatch(completeTrade({token: token, trade: trade }));
    }
  };

  const runSearchHandler = useCallback(
    async (filters) => {
      const { dateFrom, dateTo } = filters;
      const token = await getAccessTokenSilently();
      dispatch(fetchCompleteTrades({ token: token, dateFrom: dateFrom, dateTo: dateTo }));
    },
    [dispatch, getAccessTokenSilently]
  );

  const refreshOpenTradesHandler = useCallback(async () => {
    const token = await getAccessTokenSilently();
    dispatch(fetchOpenTrades({ token: token }));
  }, [dispatch, getAccessTokenSilently]);

  const showTradeDetailHandler = useCallback(
    async (trade) => {
      if (trade.signalId) {
        const token = await getAccessTokenSilently();
        dispatch(fetchTradeSignal({ token: token, trade: trade, signalId: trade.signalId }));
      }
      setSelectedTrade(trade);
      setTradeDetailDialogOpen(true);
    },
    [dispatch, getAccessTokenSilently]
  );

  const tradeDetailDialogCloseHandler = () => {
    setTradeDetailDialogOpen(false);
  };

  const ignoreTradeHandler = useCallback(
    async (trade) => {
      const token = await getAccessTokenSilently();
      dispatch(ignoreTapTrade({ token: token, tapTrade: trade }));
      setTradeEditDialogOpen(false);
    },
    [dispatch, getAccessTokenSilently]
  );

  return (
    <div>
      {searchMode && (
        <Box>
          <Box sx={{ marginBottom: 1 }}>
            <SearchFilters
              onFilterChange={runSearchHandler}
              initialDateFrom={parseISO(searchFilters.dateFrom)}
              initialDateTo={parseISO(searchFilters.dateTo)}
            />
          </Box>
          <TapTradeGrid
            showLineActions={true}
            showRevenue={true}
            showHeadersAndFooters={true}
            showTradeDetailIcon={true}
            showTags={true}
            rows={closedTrades}
            onEditTrade={tradeEditClickHandler}
            onShowTradeDetail={showTradeDetailHandler}
          />
        </Box>
      )}
      {!searchMode && (
        <TapTradeGrid
          showMultiActions={true}
          showLineActions={true}
          showHeadersAndFooters={true}
          showTags={true}
          showTradeDetailIcon={true}
          rows={openTrades}
          onGroupTrade={groupTradesHandler}
          onEditTrade={tradeEditClickHandler}
          onCompleteTrade={completeTradeHandler}
          onRefreshTrades={refreshOpenTradesHandler}
          onSplitTrade={splitTradeHandler}
          onShowTradeDetail={showTradeDetailHandler}
        />
      )}
      {tradeEditDialogOpen && (
        <TapTradeEditDialog
          open={tradeEditDialogOpen}
          handleClose={tradeEditCloseHandler}
          selectedTradeId={selectedTrade.tapTradeId}
          allowClear={!searchMode}
          showLegLineActions={!searchMode}
          handleIgnore={ignoreTradeHandler}
        />
      )}
      {tradeDetailDialogOpen && (
        <TapTradeDetailDialog
          open={tradeDetailDialogOpen}
          selectedTradeId={selectedTrade.tapTradeId}
          handleClose={tradeDetailDialogCloseHandler}
        />
      )}
    </div>
  );
};

export default TapTradeList;
