import { format, parseISO } from "date-fns";
import { Box } from "@mui/material";
import { DataGridPremium } from "@mui/x-data-grid-premium";

const SignalText = (props) => {
  const { trade } = props;

  const createData = (type, action, price, limit, stop, time) => {
    return { type, action, price, limit, stop, time };
  };

  const rows = [
    createData(
      "Signal",
      trade.signal?.action,
      trade.signal?.price,
      trade.signal?.limit,
      trade.signal?.stop,
      trade.signal?.signalTime
    ),
    createData(
      "Trade",
      trade.isBuy ? "BUY" : "SELL",
      trade.openingPrice,
      null,
      null,
      trade.tradeEntryTime
    ),
  ];

  const columns = [
    {
      field: "type",
      headerName: "Type",
      flex: 0.25,
      type: "string"
    },
    {
      field: "action",
      headerName: "Action",
      flex: 0.25,
      type: "string"
    },
    {
      field: "price",
      headerName: "Price",
      type: "number",
      flex: 0.25,
    },    
    {
      field: "limit",
      headerName: "Limit",
      type: "number",
      flex: 0.25,
    },   
    {
      field: "stop",
      headerName: "Stop",
      type: "number",
      flex: 0.25,
    },       
    {
      field: "time",
      headerName: "Time",
      flex: 0.25,
      type: "dateTime",
      valueFormatter: (params) => {
        return format(parseISO(params.value), "dd/MM/yyyy HH:mm:ss");
      },
    },
  ];

  if (trade.signal) {
    return (    
      <Box sx={{ height: "100%", width: "100%" }}>
        <DataGridPremium
          rowHeight={30}
          autoHeight
          rows={rows}
          columns={columns}
          disableSelectionOnClick
          getRowId={(row) => row.type}
          hideFooter={true}
        />
      </Box>
    );    
  } else {
    return "<None>";
  }
};

export default SignalText;
