import Button from "@mui/material/Button";
import RefreshIcon from "@mui/icons-material/Refresh";

const RefreshButton = (props) => {
  const { onClick } = props;
  return (
    <Button
      variant="outlined"
      component="span"
      startIcon={<RefreshIcon />}
      size="small"
      sx={{ marginBottom: 1 }}
      onClick={ onClick }
    >
      Refresh
    </Button>
  );
};

export default RefreshButton;
