import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import API from "../api/api";
import { format } from "date-fns";

const tapTradeListSlice = createSlice({
  name: "tapTradeList",
  initialState: {
    openTrades: [],
    closedTrades: [],
    closedTradeFilters: { dateFrom: new Date().toISOString(), dateTo: new Date().toISOString() },
    tradeTags: [],
    ignoredTrades: [],
  },
  reducers: {
    updateTrades(state, action) {
      let { trades } = action.payload;
      trades = Array.isArray(trades) ? trades : [trades];
      updateTradesInState(state, trades);
    },
    completeTrade(state, action) {
      const { trade } = action.payload;

      // remove from the open trades collection
      let newState = [...state.openTrades.filter((r) => r.tapTradeId !== trade.tapTradeId)];
      state.openTrades = newState;

      // add to the closed trades collection if it fits the current search parameters
      if (
        (trade.earliestTradeDate >= state.closedTradeFilters.dateFrom &&
          trade.earliestTradeDate <= state.closedTradeFilters.dateTo) ||
        (trade.latestTradeDate >= state.closedTradeFilters.dateFrom &&
          trade.latestTradeDate <= state.closedTradeFilters.dateTo)
      ) {
        state.closedTrades = state.closedTrades.concat(trade);
      }
    },
    addTrades(state, action) {
      let { trades } = action.payload;
      trades = Array.isArray(trades) ? trades : [trades];
      updateTradesInState(state, trades);
    },
    removeTrades(state, action) {
      let { trades } = action.payload;
      trades = Array.isArray(trades) ? trades : [trades];
      trades.forEach((trade) => {
        removeTradeFromAllState(state, trade);
      });
    },
  },
  extraReducers(builder) {
    builder
      /***** Fetching open trades *****/
      .addCase(fetchOpenTrades.pending, (state, action) => {
        // set a pending status on the state so we show the loader
      })
      .addCase(fetchOpenTrades.fulfilled, (state, action) => {
        const { trades } = action.payload;
        state.openTrades = trades;
      })
      .addCase(fetchOpenTrades.rejected, (state, action) => {
        // set an error status on the state so we show the error
      })

      /***** Fetching complete trades *****/
      .addCase(fetchCompleteTrades.pending, (state, action) => {
        // set a pending status on the state so we show the loader
      })
      .addCase(fetchCompleteTrades.fulfilled, (state, action) => {
        state.closedTrades = action.payload.trades;
        state.closedTradeFilters.dateFrom = action.payload.dateFrom;
        state.closedTradeFilters.dateTo = action.payload.dateTo;
      })
      .addCase(fetchCompleteTrades.rejected, (state, action) => {
        // set an error status on the state so we show the error
      })

      /***** Fetching ignored tap trades *****/
      .addCase(fetchIgnoredTapTrades.pending, (state, action) => {
        // set a pending status on the state so we show the loader
      })
      .addCase(fetchIgnoredTapTrades.fulfilled, (state, action) => {
        state.ignoredTrades = action.payload.trades;
      })
      .addCase(fetchIgnoredTapTrades.rejected, (state, action) => {
        // set an error status on the state so we show the error
      })

      /***** Fetching a signal for an individual trade *****/
      .addCase(fetchTradeSignal.pending, (state, action) => {
        // set a pending status on the state so we show the loader
      })
      .addCase(fetchTradeSignal.fulfilled, (state, action) => {
        const updatedTrade = { ...action.payload.trade };
        updatedTrade.signal = { ...action.payload.signal };
        updateTradesInState(state, [updatedTrade]);
      })
      .addCase(fetchTradeSignal.rejected, (state, action) => {
        // set an error status on the state so we show the error
      })

      /***** Updating trades *****/
      .addCase(updateTrade.pending, (state, action) => {
        // set a pending status on the state so we show the loader
      })
      .addCase(updateTrade.fulfilled, (state, action) => {
        // updates handled by the signal hub
      })
      .addCase(updateTrade.rejected, (state, action) => {
        // set an error status on the state so we show the error
      })

      /***** Completing a trade ******/
      .addCase(completeTrade.pending, (state, action) => {
        // set a pending status on the state so we show the loader
      })
      .addCase(completeTrade.fulfilled, (state, action) => {
        // updates handled by the signal hub
      })
      .addCase(completeTrade.rejected, (state, action) => {
        // set an error status on the state so we show the error
      })

      /***** Grouping trades *****/
      .addCase(groupTrades.pending, (state, action) => {
        // set a pending status on the state so we show the loader
      })
      .addCase(groupTrades.fulfilled, (state, action) => {
        // updates handled by the signal hub
      })
      .addCase(groupTrades.rejected, (state, action) => {
        // set an error status on the state so we show the error
      })

      /***** Splitting a trade *****/
      .addCase(splitTrade.pending, (state, action) => {
        // set a pending status on the state so we show the loader
      })
      .addCase(splitTrade.fulfilled, (state, action) => {
        // updates handled by the signal hub
      })
      .addCase(splitTrade.rejected, (state, action) => {
        // set an error status on the state so we show the error
      })

      /***** Fetching all trade tags *****/
      .addCase(fetchAllTags.pending, (state, action) => {
        // set a pending status on the state so we show the loader
      })
      .addCase(fetchAllTags.fulfilled, (state, action) => {
        const { tags } = action.payload;
        state.tradeTags = tags;
      })
      .addCase(fetchAllTags.rejected, (state, action) => {
        // set an error status on the state so we show the error
      });
  },
});

// private helpers to update a given trade in the correct state collection
const updateTradesInState = (state, updatedTrades) => {
  updatedTrades.forEach((trade) => {
    removeTradeFromAllState(state, trade);
    addTradeToState(state, trade);
  });
};

const removeTradeFromAllState = (state, trade) => {
  removeTradeFromState(trade, state.openTrades);
  removeTradeFromState(trade, state.closedTrades);
  removeTradeFromState(trade, state.ignoredTrades);
};

const removeTradeFromState = (trade, collection) => {
  const index = collection.findIndex((i) => i.tapTradeId === trade.tapTradeId);
  if (index > -1) {
    collection.splice(index, 1);
  }
};

const addTradeToState = (state, trade) => {
  // only add to closed if the trade matches the closed search criteria
  if (trade.isClosed) {
    if (
      (trade.earliestTradeDate >= state.closedTradeFilters.dateFrom &&
        trade.earliestTradeDate <= state.closedTradeFilters.dateTo) ||
      (trade.latestTradeDate >= state.closedTradeFilters.dateFrom &&
        trade.latestTradeDate <= state.closedTradeFilters.dateTo)
    ) {
      state.closedTrades = state.closedTrades.concat(trade);
    }
  }

  // there are no search criteria on open
  if (trade.isOpen) {
    state.openTrades.push(trade);
  }

  // only add to ignored if the trade matches the ignored search criteria
  if (trade.isIgnored) {
    state.ignoredTrades.push(trade);
  }
};

// fetch open trades
// input is { token }
// output is { trades }
export const fetchOpenTrades = createAsyncThunk("tapTrades/fetchOpenTrades", async (payload) => {
  const { token } = payload;
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const response = await API.get("TapTradeOpen", config);
  return { trades: response.data };
});

// fetch complete trades
// input is { token, dateFrom, dateTo }
// output is { trades, dateFrom, dateTo }
export const fetchCompleteTrades = createAsyncThunk(
  "tapTrades/fetchCompleteTrades",
  async (payload) => {
    const { token, dateFrom, dateTo } = payload;

    const formattedDateFrom = format(dateFrom, "dd/MMM/yyyy");
    const formattedDateTo = format(dateTo, "dd/MMM/yyyy");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      params: { dateFrom: formattedDateFrom, dateTo: formattedDateTo },
    };
    const response = await API.get("TapTradeCompleted", config);
    return {
      trades: response.data,
      dateFrom: new Date(dateFrom).toISOString(),
      dateTo: new Date(dateTo).toISOString(),
    };
  }
);

// fetch ignored trades
// input is { token, dateFrom, dateTo }
// output is { trades, dateFrom, dateTo }
export const fetchIgnoredTapTrades = createAsyncThunk(
  "tapTrades/fetchIgnoredTrades",
  async (payload) => {
    const { token, dateFrom, dateTo } = payload;

    const formattedDateFrom = format(dateFrom, "dd/MMM/yyyy");
    const formattedDateTo = format(dateTo, "dd/MMM/yyyy");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      params: { dateFrom: formattedDateFrom, dateTo: formattedDateTo },
    };

    const response = await API.get("TapTradeIgnored", config);
    return {
      trades: response.data,
      dateFrom: new Date(dateFrom).toISOString(),
      dateTo: new Date(dateTo).toISOString(),
    };
  }
);

// fetch the signal for a trade
// input is { token, trade, signalId }
// output is { signal, trade }
export const fetchTradeSignal = createAsyncThunk("tapTrades/fetchTradeSignal", async (payload) => {
  const { token, trade, signalId } = payload;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: { dateFrom: null, dateTo: null, signalId: signalId },
  };

  const response = await API.get("Signal", config);
  return { signal: response.data[0], trade: trade };
});

// update trade
// input is { token, trade }
// output is { trade }
export const updateTrade = createAsyncThunk("tapTrades/updateTrade", async (payload) => {
  const { token, trade } = payload;
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const response = await API.put("TapTrade", trade, config);
  return { trades: response.data };
});

// complete a trade
// input is { token, trade }
// output is { oldTrade, newTrade }
export const completeTrade = createAsyncThunk("tapTrades/completeTrade", async (payload) => {
  const { token, trade } = payload;
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const response = await API.post("TapTradeCompleted", trade, config);
  return { oldTrade: trade, newTrade: response.data };
});

// group trades together
// input is { token, trades }
// output is { oldTrades, newTrades }
export const groupTrades = createAsyncThunk("tapTrades/groupTrades", async (payload) => {
  const { token, trades } = payload;
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const response = await API.put("TapTradeGrouped", trades, config);
  return { oldTrades: trades, newTrades: response.data };
});

// split a trade apart
// input is { token, trade }
// output is { oldTrade, newTrades }
export const splitTrade = createAsyncThunk("tapTrades/splitTrade", async (payload) => {
  const { token, trade } = payload;
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const response = await API.post("TapTradeSplit", trade, config);
  return { oldTrade: trade, newTrades: response.data };
});

// fetch the list of trade tags
// input is { token }
// output is { tags }
export const fetchAllTags = createAsyncThunk("tapTrades/fetchAllTags", async (payload) => {
  const { token } = payload;
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const response = await API.get("TapTradeTag", config);
  return { tags: response.data };
});

// ignore a trade
// input is { token, tapTrade }
// output is { trade }
export const ignoreTapTrade = createAsyncThunk("tapTrades/ignoreTrade", async (payload) => {
  const { token, tapTrade } = payload;
  const postedData = { ...tapTrade };
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const response = await API.post("TapTradeIgnored", postedData, config);
  return { trades: response.data };
});

// unignore a trade
// input is { token, tapTrade }
// output is { trade }
export const unignoreTapTrade = createAsyncThunk("tapTrades/unignoreTrade", async (payload) => {
  const { token, tapTrade } = payload;
  const postedData = { ...tapTrade };
  const config = { headers: { Authorization: `Bearer ${token}` } };
  const response = await API.put("TapTradeIgnored", postedData, config);
  return { trades: response.data };
});

// allow for a trade to be collected from open or closed
export const selectAllTrades = (state) =>
  state.tapTradeList.openTrades.concat(state.tapTradeList.closedTrades);

// momoized selector for getting a single trade from the state collection
export const selectTradeById = createSelector(
  [selectAllTrades, (state, tradeId) => tradeId],
  (trades, tradeId) => trades.filter((trade) => trade.tapTradeId === tradeId)[0]
);

export const tapTradeListActions = tapTradeListSlice.actions;
export default tapTradeListSlice;
