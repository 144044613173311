import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";

const modeLight = "light";
const modeDark = "dark";

const ThemeContext = createContext({
  toggleColorMode: () => { },
  colorMode: modeLight,
  theme: modeLight
});

export const ThemeContextProvider = (props) => {  
  let currentMode = localStorage.getItem("mode");
  currentMode = currentMode ? currentMode : modeLight;
  const [mode, setMode] = useState(currentMode);
  const toggleColorMode = () => {
    setMode((prevMode) => {
      const newMode = (prevMode === modeLight ? modeDark : modeLight)
      localStorage.setItem("mode", newMode);
      return newMode;
    });
  };
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode]
  );

  return (
    <ThemeContext.Provider value={{ toggleColorMode: toggleColorMode, mode: mode, theme: theme }}>
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeContext;
