import { Fragment, useState } from "react";
import Button from "@mui/material/Button";
import DriveFolderUpload from "@mui/icons-material/DriveFolderUpload";

const UploadButton = (props) => {
  const { onChange } = props;
  const [uploadText, setUploadText] = useState("");

  const handleChange = async (event) => {    
    await onChange(event.target.files[0]);
    setUploadText("");
  }

  return (
    <Fragment>
      <input
        accept="*"
        style={{ display: "none" }}
        id="raised-button-file"
        type="file"
        onChange={handleChange}
        value={uploadText}
      />
      <label htmlFor="raised-button-file">
        <Button
          variant="outlined"
          component="span"
          startIcon={<DriveFolderUpload />}
          size="small"
          sx={{ marginBottom: 1 }}
        >
          Upload
        </Button>
      </label>
    </Fragment>
  );
};

export default UploadButton;
