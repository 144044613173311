import { configureStore } from "@reduxjs/toolkit";
import admisTradeListSlice from "./admisTradeListSlice";
import tapTradeListSlice from "./tapTradeListSlice";
import dialogSlice from "./dialogSlice";
import notificationSlice from "./notificationSlice";
import { signalRMiddleware, signalRStart } from "./signalrMiddleware";

const store = configureStore({
  reducer: {
    admisTradeList: admisTradeListSlice.reducer,
    tapTradeList: tapTradeListSlice.reducer,
    dialog: dialogSlice.reducer,
    notifications: notificationSlice.reducer,    
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(signalRMiddleware)
});

signalRStart(store);

export default store;
