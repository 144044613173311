import { createSlice } from "@reduxjs/toolkit";

const notificationSlice = createSlice({
  name: "notification",
  initialState: { text: "", severity: "", isOpen: false },
  reducers: {
    addError(state, action) {
      state.text = action.payload;
      state.isOpen = true;
      state.severity = "error";
      state.autoHideDuration = 10000;
    },
    addSuccess(state, action) {
      state.text = action.payload;
      state.isOpen = true;
      state.severity = "success";
      state.autoHideDuration = 3000;
    },
    closeMessage(state, action) {
      state.isOpen = false;
      state.text = "";
      state.severity = ""; 
    },
  },
});

export const notificationActions = notificationSlice.actions;
export default notificationSlice;
