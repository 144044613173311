import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";
import { useSelector } from "react-redux";
import { fetchSignalList } from "../../store/signalListActions";
import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import TapTradeLegGrid from "./TapTradeLegGrid";
import { selectTradeById } from "../../store/tapTradeListSlice";
import { Box } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

const TapTradeEditDialog = (params) => {
  const { handleClose, open, selectedTradeId, allowClear, showLegLineActions, handleIgnore } = params;

  // make sure we have the latest version of the trade from state when we start
  const tradeInState = useSelector((state) => selectTradeById(state, selectedTradeId));

  // maintain a local version of the trade so the user can elect to either save or cancel
  const [trade, setTrade] = useState({ ...tradeInState });

  const tradeTags = useSelector((state) =>
    state.tapTradeList.tradeTags.filter((t) => !t.archived || t.id === trade.tag?.id)
  );

  const { getAccessTokenSilently } = useAuth0();
  const [signalList, setSignalList] = useState(undefined);

  const handleUngroupLeg = (removedLeg) => {
    const newLegs = trade.legs.filter((l) => l.tapTradeLegId !== removedLeg.tapTradeLegId);
    let newTrade = { ...trade };
    newTrade.legs = newLegs;
    setTrade(newTrade);
  };

  const handleSignalChange = (event, value) => {
    let newTrade = { ...trade };
    newTrade.signal = value;
    setTrade(newTrade);
  };

  const handleTagChange = (event, value) => {
    let newTrade = { ...trade };
    newTrade.tag = value;
    setTrade(newTrade);
  };

  useEffect(() => {
    const fetchData = async () => {
      const tradeDate = new Date(tradeInState.earliestTradeDate);
      const dayBefore = new Date(tradeInState.earliestTradeDate).setDate(tradeDate.getDate() - 1); // allow for signals from yesterday to be added to account for overnight signals
      const token = await getAccessTokenSilently();
      const res = await fetchSignalList(token, dayBefore, tradeDate);
      const unusedSignalList = res.filter(
        (t) => t.trade === null || t.trade.tapTradeId === tradeInState.tapTradeId
      );
      setSignalList(unusedSignalList);
    };
    fetchData();
  }, [getAccessTokenSilently, tradeInState]);

  // we only render when the signal list has completed downloading else the
  // autocomplete complains that we are changing the default value
  if (signalList) {
    const initialSignal = signalList.find((t) => t.signalId === trade.signal?.signalId);
    const initialTagSelection = tradeTags.find((t) => t.id === trade.tag?.id);

    return (
      <div>
        <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"xl"}>
          <DialogTitle>            
            <Box>
              <Grid2 container sx={{ flexGrow: 1 }}>
                <Grid2>
                  <Typography variant="h5">Trade #{trade.tapTradeId}</Typography>
                </Grid2>
                <Grid2 mdOffset="auto">
                  {trade.canIgnore && (
                    <IconButton
                      size="small"
                      edge="start"
                      aria-label="ignore trade"
                      title="ignore trade"
                      onClick={() => {
                        handleIgnore(trade);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Grid2>
              </Grid2>
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>Tag:</DialogContentText>
            <Autocomplete
              id="combo-box-tags"
              options={tradeTags}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Tags" />}
              getOptionLabel={(option) => option.name}
              onChange={(event, value) => handleTagChange(event, value)}
              defaultValue={initialTagSelection}
              disableClearable={!allowClear}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={initialTagSelection || null} // make sure the autocomplete always starts in controlled mode by ensuring 'value' doesn't start as undefined
            />
            <br />
            <DialogContentText>Signal:</DialogContentText>
            <Autocomplete
              id="combo-box-signals"
              options={signalList}
              sx={{ width: 500 }}
              renderInput={(params) => <TextField {...params} label="Signals" />}
              getOptionLabel={(option) => {
                return option ? option.text : "";
              }}
              onChange={(event, value) => handleSignalChange(event, value)}
              defaultValue={initialSignal}
              disableClearable={false}
              isOptionEqualToValue={(option, value) => option.signalId === value.signalId}
              value={initialSignal || null} // make sure the autocomplete always starts in controlled mode by ensuring 'value' doesn't start as undefined
            />
            <br />
            <DialogContentText>Legs:</DialogContentText>
            <TapTradeLegGrid
              trade={trade}
              showLineActions={showLegLineActions}
              onUngroupTrade={handleUngroupLeg}
            />
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => {
                handleClose(true, null);
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleClose(false, trade);
              }}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
};
export default TapTradeEditDialog;
