import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useState } from "react";
import TabPanel from "../ui/TabPanel";
import TapTradeList from "./TapTradeList";
import AdmisTradeList from "./AdmisTradeList";
import IgnoredTradeList from "./IgnoredTradeList";

const TradeNavigation = () => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} aria-label="tab list for managing trades">
          <Tab label="Open Legs" />
          <Tab label="Open Trades" />
          <Tab label="Closed Trades" />
          <Tab label="Ignored Legs" />
          </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <AdmisTradeList />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TapTradeList searchMode={false} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TapTradeList searchMode={true} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <IgnoredTradeList />
      </TabPanel>      
    </Box>
  );
};

export default TradeNavigation;
