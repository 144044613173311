import ThemeContext from "../../store/theme-context";
import { useContext } from "react";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";

const UserSettings = () => {
  const themeCtx = useContext(ThemeContext);

  const changeThemeHandler = () => {
    themeCtx.toggleColorMode();
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: "background.default",
        color: "text.primary",
        borderRadius: 1,
        p: 3,
      }}
    >
      {themeCtx.theme.palette.mode} mode
      <IconButton sx={{ ml: 1 }} onClick={changeThemeHandler} color="inherit">
        {themeCtx.theme.palette.mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
      </IconButton>
    </Box>
  );
};

export default UserSettings;
