import { DataGridPremium } from "@mui/x-data-grid-premium";
import Box from "@mui/material/Box";
import DoneIcon from "@mui/icons-material/Done";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import { format, parseISO } from "date-fns";
import { useState } from "react";
import UploadButton from "../ui/UploadButton";
import RefreshButton from "../ui/RefreshButton";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { memo } from "react";
import clsx from "clsx";
import GroupTradesButton from "../ui/GroupTradesButton";
import { Tooltip } from "@mui/material";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";

const AdmisTradeGrid = memo((props) => {
  const [selected, setSelected] = useState([]);
  const {
    onClickTrade,
    admisTradeRows,
    onCompleteTrade,
    onRefreshTrades,
    onGroupTrades,
    onUploadFile,
    onUnignoreTrade,

    showLineActions,
    showMultiActions,
    showHeadersAndFooters,
    showTradeDetailIcon,
  } = props;

  const renderActions = (params) => {
    return (
      <Box>
        {showTradeDetailIcon && (
          <Tooltip title="Show detail">
            <IconButton
              size="small"
              edge="start"
              aria-label="show leg detail"
              onClick={() => {
                onClickTrade(params.row);
              }}
            >
              <InfoIcon />
            </IconButton>
          </Tooltip>
        )}
        {params.row.canComplete && (
          <Tooltip title="Complete">
            <IconButton
              size="small"
              edge="start"
              aria-label="mark as complete"
              onClick={() => {
                onCompleteTrade(params.row);
              }}
            >
              <DoneIcon />
            </IconButton>
          </Tooltip>
        )}
        {params.row.canUnignore && (
          <Tooltip title="Restore">
            <IconButton
              size="small"
              edge="start"
              aria-label="restore to admis trades screen"
              onClick={() => {
                onUnignoreTrade(params.row);
              }}
            >
              <RestoreFromTrashIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    );
  };

  const columns = [
    {
      field: "admisTradeId",
      headerName: "Id",
      flex: 0.1,
      aggregable: false,
      type: "number",
    },
    {
      field: "tradeDate",
      headerName: "Trade Date",
      flex: 0.5,
      type: "date",
      valueFormatter: (params) => {
        return format(parseISO(params.value), "dd/MM/yyyy");
      },
    },
    {
      field: "earliestEntryTime",
      headerName: "First Entry Time",
      flex: 0.5,
      type: "dateTime",
      valueFormatter: (params) => {
        return format(parseISO(params.value), "HH:mm:ss");
      },
    },
    {
      field: "contractDescription",
      headerName: "Contract",
      flex: 1,
      type: "string",
    },
    {
      field: "product",
      headerName: "Product",
      flex: 0.5,
      type: "string",
      valueGetter: (params) => {
        return params.value.description;
      },
    },
    {
      field: "contractDate",
      headerName: "Period",
      flex: 0.5,
      type: "string",
      valueGetter: (params) => {
        return format(parseISO(params.value), "LLL yyyy");
      },
    },
    {
      field: "lastTradingDate",
      headerName: "Last Trading Date",
      flex: 0.5,
      type: "date",
      valueFormatter: (params) => {
        return format(parseISO(params.value), "dd/MM/yyyy");
      },
    },
    {
      field: "quantity",
      headerName: "Qty",
      flex: 0.25,
      type: "number",
      cellClassName: (params) => {
        if (params.value == null) {
          return "";
        }
        return clsx("super-app", {
          negative: params.value < 0,
          positive: params.value > 0,
          zero: params.value === 0,
        });
      },
    },
    {
      field: "price",
      headerName: "Price",
      flex: 0.25,
      type: "number",
      valueFormatter: (params) => {
        return (Math.round(params.value * 10000) / 10000).toFixed(4);
      },
    },
    {
      field: "tapUser",
      headerName: "User",
      flex: 0.5,
      type: "string",
      valueGetter: (params) => params.value.fullName,
    },
    { field: "noLegs", headerName: "Legs", flex: 0.25, type: "number" },
  ];

  if (showLineActions) {
    columns.push({
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: renderActions,
    });
  }

  return (
    <Box>
      {showMultiActions && (
        <Grid2 container sx={{ flexGrow: 1 }}>
          <Grid2>
            <GroupTradesButton
              onClick={() => {
                onGroupTrades(
                  admisTradeRows.filter((r) =>
                    selected.includes(r.admisTradeId)
                  )
                );
              }}
            />
          </Grid2>
          <Grid2 mdOffset="auto">
            <UploadButton onChange={onUploadFile} />
            <RefreshButton onClick={onRefreshTrades} />
          </Grid2>
        </Grid2>
      )}
      <Box
        sx={{
          height: "100%",
          width: "100%",
          "& .super-app.zero": {
            color: "info.dark",
            fontWeight: "600",
          },
          "& .super-app.negative": {
            color: "error.dark",
            fontWeight: "600",
          },
          "& .super-app.positive": {
            color: "success.dark",
            fontWeight: "600",
          },
        }}
      >
        <DataGridPremium
          initialState={{
            pagination: {
              pageSize: 20,
            },
            sorting: {
              sortModel: [
                { field: "tradeDate", sort: "asc" },
                { field: "earliestEntryTime", sort: "asc" },
              ],
            },
          }}
          rowHeight={30}
          autoHeight
          rows={admisTradeRows}
          columns={columns}
          rowsPerPageOptions={[5, 10, 20, 100]}
          checkboxSelection={showMultiActions}
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          getRowId={(row) => row.admisTradeId}
          onSelectionModelChange={(itm) => setSelected(itm)}
          selectionModel={selected}
          hideFooter={!showHeadersAndFooters}
        />
      </Box>
    </Box>
  );
});

export default AdmisTradeGrid;
