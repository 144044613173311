import {
  DataGridPremium,
  useKeepGroupedColumnsHidden,
  useGridApiRef,
  GridToolbar,
} from "@mui/x-data-grid-premium";
import Box from "@mui/material/Box";
import { format, parseISO } from "date-fns";
import clsx from "clsx";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";

const SignalGrid = (props) => {
  const { rows, onShowTradeDetail } = props;

  const apiRef = useGridApiRef();

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      pagination: {
        pageSize: 20,
      },
      sorting: {
        sortModel: [
          { field: "signalTime", sort: "asc" },
          { field: "strategy", sort: "asc" },
        ],
      },
      aggregation: {
        model: {
          best: "sum",
          worst: "sum",
          actual: "sum",
          executionCost: "sum",
        },
      },
    },
  });

  const renderActions = (params) => {
    // hide the actions if we are in a grouped/aggregated row 
    if (isNaN(params.id)) {
      return "";
    }
    // only show the trade detail button if we have a trade
    return (
      <Box>    
        {params.row.trade && (
          <IconButton
            size="small"
            edge="start"
            aria-label="show trade legs"
            onClick={() => {
              onShowTradeDetail(params.row.trade);
            }}
          >
            <InfoIcon />
          </IconButton>
        )}
      </Box>
    );
  };

  const columns = [
    {
      field: "signalId",
      headerName: "Id",
      flex: 0.1,
      aggregable: false,
      type: "number",
    },
    {
      field: "signalTime",
      headerName: "Signal Time",
      flex: 0.5,
      type: "dateTime",
      aggregable: false,
      valueFormatter: (params) => {
        if (params.value) {
          return format(params.value, "dd/MM/yyyy HH:mm:ss");
        }
      },
      valueGetter: (params) => {
        if (params.value) {
          return parseISO(params.value);
        }
      },
    },
    {
      field: "strategy",
      headerName: "Strategy",
      flex: 0.25,
      type: "string",
      aggregable: false,
    },
    {
      field: "contract",
      headerName: "Contract",
      flex: 0.25,
      type: "string",
      aggregable: false,
    },
    {
      field: "contractDate",
      headerName: "Contract Date",
      flex: 0.25,
      aggregable: false,
      type: "dateTime",
      valueFormatter: (params) => {
        if (params.value) {
          return format(params.value, "MMM yyyy");
        }
      },
      valueGetter: (params) => {
        if (params.value) {
          return parseISO(params.value);
        }
      },
    },
    {
      field: "symbol",
      headerName: "Symbol",
      aggregable: false,
      flex: 0.25,
      type: "string",
    },
    {
      field: "action",
      headerName: "Action",
      aggregable: false,
      flex: 0.25,
      type: "string",
    },
    {
      field: "price",
      headerName: "Price",
      type: "number",
      aggregable: false,
      flex: 0.25,
      valueGetter: (params) => {
        // this always renders in the aggregate footer unless explicitly stopped
        if (isNaN(params.id)) {
          return "";
        }
        return (Math.round(params.value * 100) / 100).toFixed(2).toString();
      },
    },
    {
      field: "stop",
      headerName: "Stop",
      type: "number",
      aggregable: false,
      flex: 0.25,
      valueGetter: (params) => {
        // this always renders in the aggregate footer unless explicitly stopped
        if (isNaN(params.id)) {
          return "";
        }
        return (Math.round(params.value * 100) / 100).toFixed(2).toString();
      },
    },
    {
      field: "limit",
      headerName: "Limit",
      type: "number",
      aggregable: false,
      flex: 0.25,
      valueGetter: (params) => {
        // this always renders in the aggregate footer unless explicitly stopped
        if (isNaN(params.id)) {
          return "";
        }
        return (Math.round(params.value * 100) / 100).toFixed(2).toString();
      },
    },
    {
      field: "buyPrice",
      headerName: "Buy Price",
      type: "number",
      aggregable: false,
      flex: 0.25,
      valueGetter: (params) => {
        // this always renders in the aggregate footer unless explicitly stopped
        if (isNaN(params.id)) {
          return "";
        }
        return (Math.round(params.value * 10000) / 10000).toFixed(4).toString();
      },
    },
    {
      field: "sellPrice",
      headerName: "Sell Price",
      type: "number",
      aggregable: false,
      flex: 0.25,
      valueGetter: (params) => {
        // this always renders in the aggregate footer unless explicitly stopped
        if (isNaN(params.id)) {
          return "";
        }
        return (Math.round(params.value * 10000) / 10000).toFixed(4).toString();
      },
    },
    {
      field: "best",
      headerName: "Best",
      type: "number",
      aggregable: true,
      flex: 0.25,
      valueFormatter: (params) => {
        return (Math.round(params.value * 10000) / 10000).toFixed(4).toString();
      },
    },
    {
      field: "worst",
      headerName: "Worst",
      type: "number",
      aggregable: true,
      flex: 0.25,
      valueFormatter: (params) => {
        return (Math.round(params.value * 10000) / 10000).toFixed(4).toString();
      },
    },
    {
      field: "actual",
      headerName: "Actual",
      type: "number",
      aggregable: true,
      flex: 0.25,
      valueFormatter: (params) => {
        return (Math.round(params.value * 10000) / 10000).toFixed(4).toString();
      },
      cellClassName: (params) => {
        if (params.value == null) {
          return "";
        }

        return clsx("super-app", {
          negative: params.value < 0,
          positive: params.value > 0,
          zero: params.value === 0,
        });
      },
    },
    {
      field: "executionCost",
      headerName: "Execution Cost",
      type: "number",
      aggregable: true,
      flex: 0.25,
      valueFormatter: (params) => {
        return (Math.round(params.value * 10000) / 10000).toFixed(4).toString();
      },
      cellClassName: (params) => {
        if (params.value == null) {
          return "";
        }

        return clsx("super-app", {
          negative: params.value < 0,
          positive: params.value > 0,
          zero: params.value === 0,
        });
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: renderActions,
      aggregable: false,
    },
  ];

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        "& .super-app.zero": {
          color: "info.dark",
          fontWeight: "600",
        },
        "& .super-app.negative": {
          color: "error.dark",
          fontWeight: "600",
        },
        "& .super-app.positive": {
          color: "success.dark",
          fontWeight: "600",
        },
      }}
    >
      <DataGridPremium
        apiRef={apiRef}
        initialState={initialState}
        rowHeight={30}
        autoHeight
        rows={rows}
        columns={columns}
        rowsPerPageOptions={[5, 10, 20, 100]}
        checkboxSelection={false}
        disableSelectionOnClick
        experimentalFeatures={{
          newEditingApi: true,
          aggregation: true,
        }}
        getRowId={(row) => row.signalId}
        components={{ Toolbar: GridToolbar }}
      />
    </Box>
  );
};

export default SignalGrid;
