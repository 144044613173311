import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { green, blue } from "@mui/material/colors";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";

const Header = () => {
  const { logout, loginWithRedirect, isAuthenticated } = useAuth0();

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  const handleLogin = async () => {
    await loginWithRedirect();
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          {isAuthenticated && (
            <Stack direction="row" spacing={2} sx={{ flexGrow: 1 }}>
              <Link to={"/trades"} style={{ textDecoration: 'none' }}>
                <Avatar sx={{ bgcolor: green[500] }}>T</Avatar>
              </Link>
              <Link to={"/signals"} style={{ textDecoration: 'none' }}>
                <Avatar sx={{ bgcolor: blue[500] }}>S</Avatar>
              </Link>
            </Stack>
          )}
          {isAuthenticated && (
            <Button color="inherit" onClick={handleLogout}>
              Logout
            </Button>
          )}
          {!isAuthenticated && (
            <Button color="inherit" onClick={handleLogin}>
              Login
            </Button>
          )}
          {isAuthenticated && (
            <Button color="inherit" component={Link} to="/user/profile">
              Profile
            </Button>
          )}
          {isAuthenticated && (
            <Button color="inherit" component={Link} to="/">
              Home
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
