import { Route, Routes, Navigate } from "react-router-dom";
import HomePage from "./pages/HomePage";
import { Fragment, useContext, useEffect } from "react";
import Header from "./components/ui/Header";
import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import UserSettingsPage from "./pages/user/UserSettingsPage";
import UserProfilePage from "./pages/user/UserProfilePage";
import { CssBaseline } from "@mui/material";
import ThemeContext from "./store/theme-context";
import { useDispatch } from "react-redux";
import TradeNavigation from "./components/trades/TradeNavigation";
import { useAuth0 } from "@auth0/auth0-react";
import ProtectedRoutes from "./auth/protected-routes";
import PageLoader from "./components/ui/PageLoader";
import { LicenseInfo } from "@mui/x-license-pro";
import ConfirmDialog from "./components/ui/ConfirmDialog";
import NotificationFooter from "./components/ui/NotificationFooter";
import SignalList from "./components/signals/SignalList";
import { closeDialog } from "./store/dialogSlice";
import { fetchOpenAdmisTrades } from "./store/admisTradeListSlice";
import { fetchOpenTrades, fetchAllTags } from "./store/tapTradeListSlice";

function App() {
  const themeCtx = useContext(ThemeContext);
  const dispatch = useDispatch();
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_DATAGRID_LICENSE);

  useEffect(() => {
    const fetchData = async () => {
      const token = await getAccessTokenSilently();
      dispatch(fetchOpenTrades({ token: token }));
      dispatch(fetchOpenAdmisTrades({ token: token }));
      dispatch(fetchAllTags({ token: token }));
    };

    if (isAuthenticated && !isLoading) {
      fetchData();
    }
  }, [dispatch, isAuthenticated, isLoading, getAccessTokenSilently]);

  const handleCloseDialog = () => {
    dispatch(closeDialog());
  };

  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }

  return (
    <ThemeProvider theme={themeCtx.theme}>
      <CssBaseline>
        <Fragment>
          <Header />
          <br />
          <Box sx={{ padding: 1 }}>
            <Routes>
              <Route path="/" element={<Navigate to="/home" replace />} />
              <Route path="/home" element={<HomePage />} />
              <Route path="*" element={<Navigate to="/" replace />} />

              <Route element={<ProtectedRoutes />}>
                <Route path="/trades" element={<TradeNavigation />} />
                <Route path="/signals" element={<SignalList />} />
                <Route path="/user/settings" element={<UserSettingsPage />} />
                <Route path="/user/profile" element={<UserProfilePage />} />
              </Route>
            </Routes>
            <ConfirmDialog onClose={handleCloseDialog} />
          </Box>
          <NotificationFooter />
        </Fragment>
      </CssBaseline>
    </ThemeProvider>
  );
}

export default App;
