import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { tapTradeListActions } from "./tapTradeListSlice";
import { admisTradeListActions } from "./admisTradeListSlice";
import { notificationActions } from "./notificationSlice";

export const signalRMiddleware = (store) => (next) => (action) => {
  // placeholder for any dispatch actions we want to trap and send signalR data to the hub
  return next(action);
};

export const signalRStart = (store) => {
  let hubConnection = new HubConnectionBuilder()
    .withUrl(`${process.env.REACT_APP_SERVER_URL}hubs/trade`)
    .configureLogging(LogLevel.Information)
    .build();

  const connectionStart = async (showSuccess) => {
    try {
      await hubConnection.start();
      if (showSuccess) {
        const message = "The connection to the server has been re-established";
        store.dispatch(notificationActions.addSuccess(message));
      }
    } catch (err) {
      const message = `An error has occurred trying to connect to the server ${err}. Reconnecting....`;
      store.dispatch(notificationActions.addError(message));
      setTimeout(() => connectionStart(showSuccess), 5000);
    }
  };

  hubConnection.onclose((error) => {
    const message = `Connection closed due to error "${error}". Reconnecting...`;
    store.dispatch(notificationActions.addError(message));
    connectionStart(true); // manually handle reconnects so we have more control over the messages to the user
  });

  // Start the connection here
  connectionStart(false);

  /* trade hub methods */
  hubConnection.on("ReceiveUpdatedTapTrades", (data) => {
    console.log("Updated trade received!");
    store.dispatch(tapTradeListActions.updateTrades({ trades: data }));
  });

  hubConnection.on("ReceiveGroupedTapTrades", (oldTradeData, newTradeData) => {
    console.log("Grouped tap trade received!");
    store.dispatch(tapTradeListActions.removeTrades({ trades: oldTradeData }));
    store.dispatch(tapTradeListActions.addTrades({ trades: newTradeData }));
  });  

  hubConnection.on("ReceiveNewTapTrade", (admisTrade, tapTrade) => {
    console.log("New tap trade received!");
    store.dispatch(admisTradeListActions.removeTrades({ trades: admisTrade }));  
    store.dispatch(tapTradeListActions.addTrades({ trades: tapTrade }));
  });  

  hubConnection.on("ReceiveSplitTapTrade", (oldTrade, newTrades) => {
    console.log("Split tap trade received!");
    store.dispatch(tapTradeListActions.removeTrades({ trades: oldTrade }));
    store.dispatch(tapTradeListActions.addTrades({ trades: newTrades }));
  }); 
  
  hubConnection.on("ReceiveNewAdmisTrades", (data) => {
    console.log("New admis trades received!");
    store.dispatch(admisTradeListActions.updateTrades({ trades: data }));
  });

  hubConnection.on("ReceiveGroupedAdmisTrades", (oldTradeData, newTradeData) => {
    console.log("Grouped admis trade received!");    
    store.dispatch(admisTradeListActions.removeTrades({ trades: oldTradeData }));  
    store.dispatch(admisTradeListActions.addTrades({ trades: newTradeData }));  
  });    

  hubConnection.on("ReceiveUpdatedAdmisTrades", (updatedTrades) => {
    console.log("Updated admis trades received!");    
    store.dispatch(admisTradeListActions.updateTrades({ trades: updatedTrades }));
  }); 
};
