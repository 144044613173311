import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useSelector } from "react-redux";
import SignalText from "../signals/SignalText";
import { Typography } from "@mui/material";
import TapTradeLegGrid from "./TapTradeLegGrid";
import TapTradeGrid from "./TapTradeGrid";
import { selectTradeById } from "../../store/tapTradeListSlice";

const TapTradeDetailDialog = (props) => {
  const { selectedTradeId, open, handleClose } = props;
  const trade = useSelector((state) => selectTradeById(state, selectedTradeId));

  if (trade) {
    return (
      <div>
        <Dialog open={open} fullWidth={true} maxWidth={"xl"}>
          <DialogTitle>Trade #{trade.tapTradeId}</DialogTitle>
          <DialogContent>
            <Typography variant="h6">Trade</Typography>
            <TapTradeGrid rows={[trade]} />

            <Typography variant="h6">
              <br />
              Tag
            </Typography>
            <Typography variant="body">{trade.tag ? trade.tag.name : "<None>"}</Typography>

            <Typography variant="h6">
              <br />
              Signal #{trade.signal?.signalId}
            </Typography>
            <SignalText trade={trade} />

            <Typography variant="h6">
              <br />
              Legs
            </Typography>
            <TapTradeLegGrid trade={trade} />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };
};
export default TapTradeDetailDialog;
