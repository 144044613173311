import API from "../api/api";
import { format } from "date-fns";

export const fetchSignalList = (token, dateFrom, dateTo) => {
  const formattedDateFrom = format(dateFrom, "dd/MMM/yyyy");
  const formattedDateTo = format(dateTo, "dd/MMM/yyyy");

  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: { dateFrom: formattedDateFrom, dateTo: formattedDateTo },
  };
  return API.get("Signal", config)
    .then((response) => response.data)
    .catch((error) => {
      if (error.response) {
        console.log("Request made but the server responded with an error: " + error);
      } else if (error.request) {
        console.log("Request made but no response is received from the server." + error);
      } else {
        console.log("Error occured while setting up the request: " + error);
      }
    });
};
