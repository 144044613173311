import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { CardActionArea } from "@mui/material";
import { Link } from "react-router-dom";

const NavCard = (props) => {
  const to = props.to;
  const label = props.label;
  const title = props.title;
  const text = props.text;
  const avatarText = props.avatarText;
  const avatarStyle = props.avatarStyle;

  return (
    <Card>
      <CardActionArea component={Link} to={to}>
        <CardHeader
          avatar={
            <Avatar sx={avatarStyle} aria-label={label}>
              {avatarText}
            </Avatar>
          }
          title={title}
        />
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {text}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default NavCard;
